import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import slugify from '@sindresorhus/slugify'
import { radius } from 'theme'
import { formatToNumberWithCommas } from 'helpers'
import App from 'App'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import { H1, H2, H4, Text } from 'components/Typography'
import Container from 'components/Container'
import LocalImage from 'components/LocalImage'
import Input from 'components/Input'
import Footer from 'components/Footer'
import Link from 'components/Link'
import search from 'static/svg/search.svg'

const StyledImage = styled(LocalImage)`
  cursor: pointer;
`

const Cities = ({
  pageContext: {
    cities,
    seoFriendlyCountryName,
    countryName,
    countryCode,
    seoFriendlyStateName,
    stateName,
    stateCode,
    agenciesNumber,
  },
}) => {
  const handleSubmitSearch = (e) => {
    e.preventDefault()
    console.log('searching')
  }

  return (
    <App>
      <SEO
        title={`Top social media marketing and digital agencies in ${seoFriendlyStateName ? `${seoFriendlyStateName}, ` : ''
          }${seoFriendlyCountryName}`}
        description={`Top social media marketing and digital agencies in ${seoFriendlyStateName ? `${seoFriendlyStateName}, ` : ''
          } ${seoFriendlyCountryName}`}
        path={`/top-marketing-agencies/${seoFriendlyCountryName}/${seoFriendlyStateName || ''}`}
      />
      <Container maxWidth="1080px">
        <Flex mt="xl" flexDirection="column" alignItems="center" textAlign="center">
          <H2>{`Top marketing agencies in ${stateName ? `${stateName}, ` : ''}${countryName}`}</H2>
          <H4 mt="m" color="secondaryText" opacity={0.8}>
            Connect with thousands of top marketing agencies in {stateName ? `${stateName}, ` : ''}
            {countryName}.
          </H4>
          {/*
          <Box my="l">
            <form onSubmit={handleSubmitSearch}>
              <Input
                width="300px"
                placeholder="Search"
                pl="40px"
                iconPosition={{ left: '10px' }}
                iconComp={<StyledImage src={search} onClick={handleSubmitSearch} />}
                hasBoxShadow
                borderRadius={radius.m}
              />
            </form>
          </Box>
          */}
          <Grid
            mt="m"
            py="m"
            px="s"
            width={['auto', '900px']}
            gridTemplateColumns={['1fr 1fr', 'repeat(4, 1fr)']}
            gridGap="m"
          >
            {cities.map(({ name, value }) => {
              if (name) {
                return (
                  <Text
                    key={name}
                    as={Link}
                    href={`/marketing-agencies/country/${slugify(countryName)}${stateName ? `/${slugify(stateName)}` : ''
                      }/${slugify(name)}`}
                    textAlign="left"
                    fontSize="m"
                    fontWeight="medium"
                    antiAliased
                    color="primary"
                  >
                    {`${name} (${formatToNumberWithCommas(value)})`}
                  </Text>
                )
              }
            })}
            {agenciesNumber > 0 && (
              <Text
                as={Link}
                href={`/marketing-agencies/country/${slugify(countryName)}${stateName ? `/${slugify(stateName)}` : ''}`}
                textAlign="left"
                fontSize="m"
                fontWeight="medium"
                antiAliased
                color="primary"
              >
                {`All agencies in ${stateName || countryName} (${formatToNumberWithCommas(agenciesNumber)})`}
              </Text>
            )}
          </Grid>

          <Grid
            mt="xxl"
            py="s"
            px="s"
            width={['auto', '900px']}
            gridTemplateColumns={['1fr 1fr', 'repeat(2, 1fr)']}
            gridGap="m"
          >
            {cities.map(({ name, value }) => {
              if (name) {
                return (
                  <Text
                    key={name}
                    as={Link}
                    href={`/marketing-agencies/country/${slugify(countryName)}${stateName ? `/${slugify(stateName)}` : ''
                      }/${slugify(name)}`}
                    textAlign="left"
                    fontSize="s"
                    fontWeight="normal"
                    antiAliased
                    color="secondaryText"
                  >
                    {`Top marketing agencies in ${name}, ${stateName ? `${stateName}, ` : ''}${countryName}`}
                  </Text>
                )
              }
            })}
          </Grid>
        </Flex>
      </Container>
      <Box mt="xxl">
        <Footer />
      </Box>
    </App>
  )
}

Cities.defaultProps = {
  pageContext: {
    seoFriendlyStateName: '',
    stateName: '',
    stateCode: '',
    agenciesNumber: 0,
  },
}

Cities.propTypes = {
  pageContext: PropTypes.shape({
    cities: PropTypes.array.isRequired,
    countryName: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
    seoFriendlyCountryName: PropTypes.string.isRequired,
    seoFriendlyStateName: PropTypes.string,
    stateName: PropTypes.string,
    stateCode: PropTypes.string,
    agenciesNumber: PropTypes.number,
  }),
}

export default Cities
